.admin-view .header,
.admin-view .footer {
  display: none;
}

.admin-wrapper {
  overflow-x: hidden;
}

.admin-view .main_content {
  padding: 0;
}

.header-view {
  text-align: center;
  position: relative;
  z-index: 99;
}

.header-top-box h1 {
  font-size: 30px;
  padding: 5px 0px;
}

.admin-content-view {
  display: flex;
}

.admin-menu {
  min-height: 100vh;
  border-right: 1px solid #171717;
  width: 250px;
  background: #383d4a;
  transition: all 0.5s;
}

.admin-content {
  padding: 30px 20px;
}

.admin-container {
  width: calc(100% - 250px);
  background: #383a44;
  transition: all 0.5s;
}

.admin-menu.menu-hide {
  width: 0;
  opacity: 0;
  border: none;
}

.admin-container.wrapper-expand {
  width: 100%;
}

.menu-list ul {
  margin: 0;
  padding: 0;
}

.menu-list ul a {
  padding: 15px 15px;
  display: block;
  color: #fff;
  text-decoration: none;
  transition: all 0.5s;
  font-size: 13px;
}

.menu-list ul a:hover {
  color: #009bff !important;
}
.menu-list ul a.active {
  color: #009bff !important;
}
.admin-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  background: #383d4a;
  min-height: 60px;
  border-bottom: 1px solid #171717;
}

.menu-logo {
  text-align: center;
  background: #383d4a;
  padding: 1px 10px;
  border-bottom: 1px solid #171717;
  height: 60px;
}

.menu-logo h2 {
  font-weight: 600;
  font-size: 25px;
  color: #fff;
  margin: 0;
}

.menu-list {
  padding-top: 10px;
}

.admin-info img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  border-radius: 50px;
  margin-right: 0px;
}

.admin-info {
  display: flex;
  align-items: center;
}

.menu-logo img {
  max-width: 200px;
  padding: 11px 10px;
}

.menu-toggle img {
  width: 36px;
  opacity: 0.9;
  transition: all 0.5s;
  cursor: pointer;
}

.menu-toggle img:hover {
  opacity: 1;
}

.menu-list ul li a:hover {
  color: #fff;
  background: rgb(112, 159, 213);
  background: linear-gradient(
    180deg,
    rgba(112, 159, 213, 1) 0%,
    rgba(60, 88, 118, 1) 100%
  );
}
.admin-info h3 {
  margin: 0;
  font-size: 14px;
  color: #212529;
  font-weight: 600;
}

.menu-list ul a i {
  margin-right: 5px;
  width: 20px;
}

.dashboard .card {
  margin-bottom: 15px;
  margin-top: 15px;
  border-color: #383d4a;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  background: #383d4a;
}

.menu-toggle a {
  color: #555;
  font-size: 20px;
}

.admin-title {
  margin: 0;
  padding-bottom: 20px;
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  padding-top: 30px;
}

.font-weight-600 {
  font-weight: 600;
}

.dashboard .fa {
  font-size: 30px;
  opacity: 1;
}

.dashboard .card-body {
  cursor: pointer;
  padding: 25px 10px;
}

.dashboard .card span {
  color: #fff;
  font-size: 18px;
  /* padding-top: 25px; */
  display: block;
}

.dashboard .card-body .fa {
  transition: all 0.5s;
}

.dashboard .card-body:hover .fa {
  opacity: 1;
  color: #009bff !important;
}

.dashboard .text-muted {
  color: #050505 !important;
}

.user-list .table-responsive > .table-bordered .btn {
  background: #000;
  border: none;
  padding: 5px 20px;
  font-size: 14px;
  min-width: 90px;
}
/* 
.user-list .table td,
.table th {
  padding: 15px 20px;
  vertical-align: middle;
} */
.user-list .table td,
.table th {
  padding: 15px 20px;
  vertical-align: top;
  white-space: nowrap;
  text-align: left;
}

.user-list .table td p {
  font-size: 14px;
  color: #666;
  line-height: 1.5;
  margin: 0;
}

.user-list .table-responsive > .table-bordered {
  border: 0;
  background: #fff;
}

.user-list .table td {
  font-size: 14px;
}

.user-list .table td a {
  color: #478adc;
}

.action-buttons .btn {
  margin: 0px 5px;
  min-width: 75px;
}

.delete-btn,
.delete-btn:focus {
  background: #f12929 !important;
  border-color: #f12929 !important;
}

.block-btn,
.block-btn:focus {
  background: #009bff !important;
}

.edit-btn,
.edit-btn:focus {
  background: #383737 !important;
  border-color: #383737 !important;
}

.admin-search {
  text-align: right;
  padding-bottom: 25px;
}

.admin-search .form-inline {
  justify-content: flex-end;
}

.admin-search .btn {
  background: #555555;
  border: none;
  padding: 7px 15px;
  font-size: 14px;
}

.admin-search .form-control {
  font-size: 13px;
  padding: 8px 10px;
  height: auto;
}

.admin-login {
  background: #383a44;
  padding: 100px 0px;
  min-height: 100vh;
}

.admin-login-box {
  padding: 50px 40px;
  max-width: 500px;
  margin: 0px auto;
  background: #383d4a;
  border-radius: 5px;
}

.admin-login-box .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: #383a44;
  border: 1px solid #3e4548;
  border-radius: 4px;
  font-size: 14px;
  color: #ddd;
}

.admin-login-box .form-group .form-control:focus {
  box-shadow: none;
  background: #383a44;
}
.admin-login-box input:-internal-autofill-selected {
  background: #383a44 !important;
}

.admin-login-box h2 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: #fff;
  padding-bottom: 20px;
}

.admin-detail {
  display: flex;
  align-items: flex-start;
  padding: 20px 15px;
}
.admin-login-box label {
  color: #ddd;
}

.admin-pic img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 3px solid #fff;
}

.admin-name span {
  font-size: 12px;
  color: #ddd;
}

.admin-name h4 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 5px;
}

.admin-name {
  padding-left: 20px;
  padding-top: 5px;
}

.menu-list ul h5 {
  font-size: 12px;
  text-transform: uppercase;
  padding: 0px 15px;
  margin-top: 20px;
  color: #fff;
  margin-bottom: 10px;
}

ul.sub-menu {
  background: #5f5f5f;
  display: none;
}

ul.sub-menu li a {
  font-size: 13px;
  padding-left: 50px;
}

ul.sub-menu li a i {
  margin-right: 2px;
}

.menu-list ul li {
  position: relative;
}

.menu-list ul > li.has-menu > a:after {
  content: '\f107';
  font-family: FontAwesome;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  transition: all 0.5s;
}

ul.sub-menu.menu-open {
  display: block;
}

.menu-list ul > li.has-menu.menu-expand > a:after {
  transform: rotate(180deg);
}

.admin-info .dropdown-toggle {
  background: transparent !important;
  font-size: 12px;
  color: #fff;
  border: none !important;
}

.admin-info .dropdown-toggle:hover,
.admin-info .btn-primary:not(:disabled):not(.disabled):active:focus,
.admin-info .dropdown-toggle:focus,
.admin-info .show > .btn-primary.dropdown-toggle:focus {
  background: transparent !important;
  font-size: 12px;
  color: #fff;
  border: none;
  box-shadow: none !important;
}

.admin-info .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent !important;
  border-color: transparent !important;
  color: #333;
}

.admin-info .show > .btn-primary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
  color: #333;
}

.admin-info .dropdown-item {
  display: block;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: #f9f9f9;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  font-size: 12px;
  padding: 6px 15px;
}

.admin-info .dropdown-item:hover {
  color: #009bff;
  text-decoration: none;
  background-color: transparent;
}

.admin-info .dropdown-menu {
  min-width: 6rem;
  left: -5px !important;
  background: #383a44;
  border: 1px solid #3e4548;
  margin-top: 10px !important;
}

.admin-search .form-label {
  padding-right: 20px;
}

.admin-search select.form-control {
  max-width: 150px;
  width: 100%;
}

.add-distributor {
  padding: 50px;
  background: #fff;
  border: 1px solid #ddd;
  margin-bottom: 50px;
}

.add-distributor .form-group {
  margin-bottom: 20px;
}

.add-distributor .admin-title {
  padding-bottom: 30px;
}

.distributor-button .d-btn {
  background: #37a864;
  border: none;
  padding: 10px 30px;
  font-size: 15px;
}

.distributor-form label {
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
}

.user-list .tab-content {
  padding: 30px;
  background: #fff;
  border: 1px solid #dee2e6;
  margin-top: -1px;
  padding-top: 50px;
}

.user-list .nav-tabs .nav-link {
  padding: 20px 30px;
  font-size: 18px;
  font-weight: 600;
  width: 50%;
  text-align: center;
}

.user-list .nav-tabs .nav-link:focus,
.user-list .nav-tabs .nav-link:hover {
  outline: none;
}

.dashboard .card-body h6 {
  font-size: 12px;
  font-weight: 400;
  /* position: absolute; */
  top: 0;
  width: 100%;
  margin-bottom: 0px;
}
.admin-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.dashboard .card .admin-data span {
  padding-top: 0px;
}
/*********** Sub Admin ***************/
.admin-tab .table-responsive {
margin-top: 0;
}
.respon-table {
  overflow: auto;
}
.admin-tab .nav-tabs {
  border-bottom: 1px solid #3e4548;
}

.admin-tab .nav-tabs .nav-link.active {
  background: transparent;
  border: none;
  border-bottom: 2px solid #009bff;
  color: #009bff;
}

.admin-tab .nav-tabs .nav-link {
  color: #383d4a;
}

.admin-tab .table thead th,
.admin-tab .table tbody td {
  border: none;
  color: #fff;
  padding: 12px 10px;
  font-size: 14px;
}

.admin-tab .table tbody td {
  border-bottom: 1px solid #171717;
  vertical-align: middle;
}

.admin-tab .nav-tabs .nav-link {
  padding: 10px 30px;
}

.admin-tab .nav-tabs .nav-link:hover {
  border-color: transparent;
}

.admin-tab .nav-tabs .nav-link.active {
  border-color: transparent;
  border-bottom: 2px solid #009bff;
  font-weight: 500;
}

.action-buttons .btn {
  font-size: 12px;
}

.admin-tab .tab-content {
  padding: 30px 0px;
}

.sub-admin .admin-tab {
  padding: 30px;
  background: #383d4a;
}

.add-game .admin-tab {
  padding: 30px;
  background: #383d4a;
}

.admin-kyc .admin-tab {
  padding: 30px;
  background: #383d4a;
}

.add-subadmin-form .register-form {
  background: transparent;
  max-width: 100%;
}

.add-subadmin-form .tgl-light + .tgl-btn {
  margin: 0;
  background: #5d5d67;
}

.add-subadmin-form .s-btn.btn.btn-primary {
  max-width: 150px;
}

.add-subadmin-form .form-group label {
  font-size: 14px;
  color: #ddd;
  font-weight: 400;
}

.add-subadmin-form [type='file'] {
  height: 41px;
  overflow: hidden;
  width: 144px;
  margin: 0px auto;
  opacity: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9;
  cursor: pointer;
}

.add-game-content .register-form {
  max-width: 100%;
  padding-bottom: 50px;
  margin-bottom: 30px;
  background: transparent;
}

.add-game-content .s-btn.btn.btn-primary {
  max-width: 150px;
  font-size: 18px;
  padding: 10px 15px;
}

.add-game-content .form-group label {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  display: block;
}

.add-game-content .uplaod-profile-photo {
  text-align: left;
}

.add-game-content [type='file'] {
  height: 41px;
  overflow: hidden;
  width: 144px;
  margin: 0px auto;
  opacity: 0;
  position: absolute;
  z-index: 9;
  cursor: pointer;
}

.add-game-content [type='file'] + label.btn-2 {
  max-width: 144px;
  border-radius: 5px;
  padding: 10px 50px;
  background-color: #383a44;
  border: 1px solid #3e4548;
  position: relative;
  z-index: 9;
}

.admin-user .table thead th,
.admin-user .table tbody td {
  border: none;
  color: #fff;
  padding: 12px 10px;
  font-size: 14px;
}

.admin-user .table-responsive,
.admin-withdraw .table-responsive {
  padding: 30px;
  background: #383d4a;
}

.form-control:focus {
  color: #ddd !important;
}
.admin-user .table tbody td,
.admin-user .table thead td {
  border-top: 1px solid #3e4548;
  vertical-align: middle;
}

.add-game .table thead th,
.add-game .table tbody td {
  border: none;
  color: #ddd;
  padding: 15px 15px;
  font-size: 14px;
}

.add-game .table tbody td,
.add-game .table thead th {
  border-bottom: 1px solid #3e4548;
  vertical-align: middle;
}
.add-game .table tbody td img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  border-radius: 5px;
}

.add-game-content [type='file']:hover + label {
  background-color: #009bff !important;
}

.add-game-content [type='file'] + label.btn-2::before {
  right: inherit;
  line-height: 2.9;
  left: 16px;
}

.add-game-content .uplaod-profile-photo .form-label {
  border: none;
}

.add-game-content [type='file'] + label.btn-2:hover {
  background-color: #009bff;
  border: 1px solid #009bff;
}

.user-list img {
  width: 90px;
}

.user-list {
  text-align: center;
}

.user-popup h4 {
  text-align: center;
  color: #fff;
  margin-bottom: 20px;
}

.user-list ul {
  padding: 20px;
  margin: 0;
}

.user-list ul li {
  padding: 10px 0px;
  color: #989898;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  border-bottom: 1px dashed #282e31;
}

.user-list ul li span {
  color: #212529;
}

.user-list ul li:last-child {
  border: none;
}

.delete-popup {
  text-align: center;
  padding-bottom: 20px;
}

.delete-popup h4 {
  color: #000;
  max-width: 300px;
  margin: 0px auto;
  padding-bottom: 10px;
}

.delete-popup .btn {
  min-width: 100px;
  margin: 10px 5px;
}

.admin-pagination .pagination {
  justify-content: flex-end;
  padding: 20px 0px;
}

.admin-pagination .page-link {
  background: #383d4a;
  border: 1px solid #3e4548;
  color: #fff;
  font-size: 14px;
}

.admin-pagination .page-link:hover,
.admin-pagination .page-link:focus {
  color: #fff;
  text-decoration: none;
  background-color: #383d4a;
  border-color: #3e4548;
  box-shadow: none;
}

.add-game-content .register-form h6 {
  font-size: 20px;
  color: #ffffff;
  font-weight: 400;
  padding-bottom: 15px;
}

.subadmin-edit .form-check-label {
  font-size: 14px;
  color: #1b1717;
  font-weight: 400;
}

.alert-danger {
  background: transparent !important;
  border: none !important;
  padding: 5px 0px !important;
  font-size: 13px !important;
  color: #dc2233 !important;
  margin-bottom: 5px !important;
}

.admin-forget {
  background: #383a44;
  padding: 100px 0px;
  min-height: 100vh;
}

.admin-forget .forget-section {
  background: transparent;
}

.admin-forget .forget-form .form-group .form-control:focus {
  box-shadow: none;
  background: #383a44;
}

.admin-forget .forget-form {
  background: #383d4a;
  position: relative;
}

.admin-forget .forget-form h1 {
  color: #fff;
}

.admin-forget .forget-form p {
  color: #ddd;
}

.admin-forget .forget-form .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: #383a44;
  border: 1px solid #3e4548;
  border-radius: 4px;
  font-size: 14px;
  color: #ddd;
}

.admin-content .register-form .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: #383a44;
  border: 1px solid #3e4548;
  border-radius: 4px;
  font-size: 14px;
  color: #ddd;
}

.admin-content .register-form .form-group .form-control::placeholder {
  color: #ddd;
}

.admin-content .register-form .form-group .form-control:focus {
  background: #383a44;
  border: 1px solid #3e4548;
  box-shadow: none;
}

.format-form {
  margin: 10px 0px 50px 0px;
}
.format-form button.s-btn.btn.btn-primary {
  width: auto !important;
  padding: 8px 12px !important;
  font-size: 14px !important;
  min-width: 120px;
}

/********** KYC ***************/

.admin-kyc .table thead th,
.admin-kyc .table tbody td {
  border: none;
  color: #fff;
  padding: 12px 10px;
  font-size: 14px;
  white-space: nowrap;
}

.admin-kyc .table tbody td {
  border-top: 1px solid #3e4548;
  vertical-align: middle;
}

.admin-kyc .table tbody td {
  font-size: 13px;
}

.admin-kyc .table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #020203;
}

.admin-kyc .table-responsive::-webkit-scrollbar {
  width: 6px;
  background-color: #09090c;
}

.admin-kyc .table-responsive::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #111117;
}

/********** transaction ***************/

.admin-transaction .admin-tab {
  padding: 30px;
  background: #383d4a;
}

.admin-transaction .table thead th,
.admin-transaction .table tbody td {
  border: none;
  color: #fff;
  padding: 12px 10px;
  font-size: 14px;
}

.admin-transaction .table tbody td {
  border-top: 1px solid #3e4548;
  vertical-align: middle;
}

.admin-transaction .table tbody td {
  font-size: 13px;
}

.admin-transaction .table tbody td {
  font-size: 13px;
  /* width: 10%; */
  white-space: nowrap;
}

.proof {
  width: 80px;
}

/********** Commission ***************/

.commission-card {
  margin-bottom: 15px;
  margin-top: 15px;
  border-color: #171717;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  background: #383d4a;
  padding: 20px;
  border-radius: 5px;
}

.commission-card h2 {
  font-size: 20px;
  padding-bottom: 15px;
}

.commission-card .form-control {
  height: auto;
  padding: 10px 15px;
  background: #383a44;
  border: 1px solid #3e4548;
  border-radius: 4px;
  font-size: 14px;
  color: #ddd;
}

.commission-card .btn {
  padding: 4px 20px;
  font-size: 14px;
}

.commission-card .form-control::placeholder {
  color: #ddd;
}

.commission-card p {
  color: #bbb;
  font-size: 14px;
  padding-top: 20px;
  margin: 0;
}

.commission-card p span {
  color: #009bff;
  font-weight: 700;
}

/******************m Matchmaking *******************/

.admin-matchmaking .table thead th,
.admin-matchmaking .table tbody td {
  border: none;
  color: #fff;
  padding: 15px 10px;
  font-size: 14px;
}

.admin-matchmaking .table tbody td {
  border-top: 1px solid #3e4548;
  vertical-align: middle;
}

.admin-matchmaking .table tbody td {
  font-size: 13px;
}

.user-popup .user-list img {
  margin: 0px 10px 10px 10px;
  border-radius: 5px;
  height: 70px;
  object-fit: cover;
  cursor: pointer;
  width: 70px;
}
.ReactModalPortal {
  position: fixed;
  z-index: 9999;
}
/* notification css */
.p-name.notification-icon h6 i {
  color: white;
  font-size: 18px;
  cursor: pointer;
}

.p-name.notification-icon {
  margin-top: 10px;
  position: relative;
}

.notification-icon .badge {
  position: absolute;
  right: -9px;
  top: -14px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: block;
  border-radius: 100%;
  padding: 0;
  text-align: center;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 100;
  font-size: 10px;
}

.notification .profile-image img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.notification-popup .modal-body {
  height: 500px;
  overflow: auto;
  padding: 10px 20px;
  margin-right: -3px;
}
.notification-popup .modal-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #020203;
}

.notification-popup .modal-body::-webkit-scrollbar {
  width: 3px;
  background-color: #020203;
}

.notification-popup .modal-body::-webkit-scrollbar-thumb {
  background-color: #020203;
}
.profile-image img {
  border-radius: 50%;
  height: 40px;
}
.notification {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 15px 0px;
  padding: 5px;
  border-radius: 3px;
  cursor: pointer;
}

.notification-text h6 {
  margin-bottom: 0px;
}

.notification-text p {
  margin-bottom: 0px;
  text-align: left;
  font-size: 13px;
}
.profile-image {
  margin-right: 10px;
}
.notification-text {
  width: 100%;
}
.notification-popup .modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 18px;
  font-weight: 600;
}
.sub-admin-roles {
  color: black;
  font-size: 20px;
  font-weight: 600;
  margin: 15px 0px;
}

.back-home {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  color: #007bff;
  display: inline-block;
  text-align: center;
  position: absolute;
  left: 20px;
}

.back-home:hover {
  color: #333;
  text-decoration: none;
}

.notification-popup .modal-content * {
  color: #ddd;
}

.notification-text h6 {
  font-size: 13px;
  font-weight: 100;
  line-height: 1.7;
}

.notification-popup .notification-text {
  padding-right: 20px;
}

.notification-text p {
  color: #ddd;
  font-size: 11px;
  padding-top: 5px;
}

.notification {
  border-top: 1px solid #343434;
  border-radius: 0;
  padding-top: 20px;
  align-items: flex-start;
}

.notification .profile-image {
  margin-right: 20px;
}

.notification .profile-image img {
  border-radius: 5px;
}

/***************** Support Chat ********************/

.people-list {
  width: 30%;
  float: left;
  background: #383d4a;
  border-radius: 5px;
}
.people-list .search {
  padding: 20px;
}
.people-list input {
  border-radius: 3px;
  border: none;
  padding: 14px;
  color: white;
  background: #6a6c75;
  width: 90%;
  font-size: 14px;
}
.people-list .fa-search {
  position: relative;
  left: -25px;
}
.people-list ul {
  padding: 20px;
  height: 500px;
  overflow-y: auto;
  margin-bottom: 10px;
}
.people-list ul li {
  padding-bottom: 20px;
  cursor: pointer;
}
.people-list img {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.people-list .about {
  float: left;
  margin-top: 8px;
}
.people-list .about {
  padding-left: 8px;
}
.people-list .about .name {
  color: #fff;
}

.people-list .status {
  color: #92959e;
  font-size: 10px;
}

.adminchat {
  width: 70%;
  float: left;
  background: #242930;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #ddd;
}
.adminchat .adminchat-header {
  padding: 20px;
  border-bottom: 1px solid #2f323c;
  cursor: pointer;
}
.adminchat .adminchat-header img {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}
.adminchat .adminchat-header .adminchat-about {
  float: left;
  padding-left: 10px;
  margin-top: 6px;
}
.adminchat .adminchat-header .adminchat-with {
  font-weight: bold;
  font-size: 16px;
}
.adminchat .adminchat-header .adminchat-num-messages {
  color: #92959e;
}
.adminchat .adminchat-header .fa-star {
  float: right;
  color: #d8dadf;
  font-size: 20px;
  margin-top: 12px;
}
.adminchat .adminchat-history {
  padding: 30px 30px 20px;
  border-bottom: 1px solid #2f323c;
  overflow-y: scroll;
  height: 320px;
}
.adminchat .adminchat-history .message-data {
  margin-bottom: 15px;
}
.adminchat .adminchat-history .message-data-time {
  color: #a8aab1;
  padding-left: 6px;
  font-size: 10px;
}

.adminchat .adminchat-history .other-message img {
  width: 100%;
}

.adminchat .adminchat-history .message {
  color: white;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 30px;
  width: 90%;
  position: relative;
}

.adminchat-message .form-control {
  background-color: #383a44 !important;
  border: 1px solid #343842 !important;
  box-shadow: none !important;
  outline: none !important;
  border-radius: 5px 0 0 5px;
}
.adminchat .adminchat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #86bb71;
  border-width: 10px;
  margin-left: -10px;
}
.adminchat .adminchat-history .my-message {
  background: #86bb71;
}
.adminchat .adminchat-history .other-message {
  background: #94c2ed;
}
.adminchat .adminchat-history .other-message:after {
  border-bottom-color: #94c2ed;
  left: 93%;
}
.adminchat .adminchat-message {
  padding: 30px;
  display: flex;
}
.adminchat .adminchat-message textarea {
  width: 100%;
  border: none;
  padding: 10px 20px;
  font: 14px/22px 'Lato', Arial, sans-serif;
  margin-bottom: 10px;
  border-radius: 5px;
  resize: none;
}
.adminchat .adminchat-message .fa-file-o,
.adminchat .adminchat-message .fa-file-image-o {
  font-size: 16px;
  color: gray;
  cursor: pointer;
}
.adminchat .adminchat-message button {
  float: right;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: 500;
  background: #f2f5f8;
  min-width: 100px;
  border-radius: 0 5px 5px 0;
}
.adminchat .adminchat-message button:hover {
  color: #fff;
}
.chat-file-select [type='file'] + label.btn-2 {
  border-radius: 5px;
}
.chat-file-select [type='file'] + label {
  padding: 10px 40px;
}
.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: ' ';
  clear: both;
  height: 0;
}

.photo-upload-label {
  display: flex;
  align-items: center;
}
.photo-upload-label p {
  margin-left: 10px;
}

/* CSS by Rizwan */
.notification-content {
  display: flex;
}

.user-search-section .user-search input,
.user-search-section .user-search input:focus {
  background-color: transparent;
  color: white !important;
  font-size: 12px;
  border: 1px solid white !important;
  height: 38px;
  border-radius: 0px;
}
.user-search-section .user-search input::placeholder {
  color: white;
  font-size: 12px;
}
.user-search-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.user-search-section .user-search {
  display: flex;
  align-items: center;
  position: relative;
}
.user-search-section .user-search .form-group {
  margin-bottom: 0px;
}
.user-search-section .user-search button {
  border-radius: 0px;
  margin-left: -1px;
  /* position: absolute; */
  right: 0px;
  border: 1px solid white !important;
  border-left: none;
}

.transaction-search-box {
  position: relative;
}
.user-search-section.transaction-search {
  position: absolute;
  right: 0px;
}

.dashboard .card-body .sub-admin-data h6,
.dashboard .card-body .sub-admin-data span {
  font-size: 11px !important;
}
.admin-data-card {
  padding: 15px 10px !important;
}

/* multiselect css */
.tags-input ul li {
  color: white;
  font-weight: 300;
  margin: 5px 12px;
  margin-left: 0px;
  padding: 5px 30px;
  font-size: 12px;
  padding-left: 10px;
  display: flex;
  border-radius: 25px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #fafafa;
  position: relative;
}

.tags-input ul {
  display: flex;
  flex-wrap: wrap;
}

.tags-input ul li i {
  margin-left: 7px;
  position: absolute;
  right: 4px;
}

.tags-input ul li span {
  left: 0px;
}
.people-list .about {
  padding: 0px !important;
  border: none;
  background-color: transparent;
  border: none !important;
  text-align: left !important;
  margin-left: 5px;
}
.people-list li {
  margin: 10px 0px;
}

.user-search-section.transaction-search.despute-search {
  position: absolute;
  top: 0;
}
.admin-support-adminchat-content {
  width: 100%;
  display: flex;
}

/******************************* Media SCreen ******************************/

@media screen and (max-width: 767px) {
  .admin-container {
    width: 100%;
  }
  .admin-content {
    padding: 30px 0px;
  }

  .admin-menu {
    width: 0;
  }

  .admin-search .form-inline {
    justify-content: center;
  }

  .admin-search .btn {
    margin-top: 10px;
  }

  .user-list .table-responsive > .table-bordered .btn {
    min-width: 100px;
    margin-bottom: 5px;
  }

  .admin-menu.menu-hide {
    width: 250px;
    opacity: 1;
    border-right: 1px solid #171717;
  }

  .menu-list ul a {
    font-size: 12px;
  }

  .admin-title {
    padding-bottom: 30px;
    font-size: 20px;
  }

  .admin-content .table thead th,
  .admin-content .table tbody td {
    white-space: nowrap;
  }
}

@media screen and (min-width: 1440px) {
  .dashboard .card-body h6 {
    font-size: 14px;
  }
}
.togglebox {
  border-radius: 20px;
  border: 1px solid #c0c0ca;
  color: #898a9a;
  vertical-align: middle;
  font-family: 'Roboto', sans-serif;
  display: inline;
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #c0c0ca;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  background: transparent;
}

.dashboard {
  position: relative;
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  /* left: 50%; */
  /* top: 50%; */
  /* transform: translate(-50%, -50%); */
  /* z-index: 999; */
  background-color: black;
  opacity: 0.9;
}
.loader img {
  width: 80px;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.action-dropdown.action-buttons {
  display: flex;
  align-items: center;
}
.action-dropdown .dropdown-toggle::after {
  content: none;
}
.action-dropdown .btn-link > img {
  width: 20px;
}

.action-dropdown .dropdown-item {
  padding: 5px 10px;
}
.action-dropdown .btn-primary {
  width: 100%;
}

.user-list .nav-tabs .nav-link {
  padding: 15px 10px;
  font-size: 14px;
  font-weight: 600;
  width: 20%;
  text-align: center;
}
.admin-container {
  background: #f5f5f5;
}
.admin-content .admin-title {
  color: #050505;
}
.dashboard .card {
  background-color: white;
  border: none;
  padding: 10px;
  border-radius: 8px;
}
.dashboard .card span {
  color: #020202;
}
.admin-content-view .admin-tab {
  background-color: #fff;
}
.text-right {
  margin-top: 40px;
}
.add-game-content .form-group label {
  color: black;
  font-size: 20px;
}

.admin-tab .table thead th,
.admin-tab .table tbody td {
  border: none;
  color: #040404 !important;
}
.admin-user .table thead th,
.admin-user .table tbody td {
  border: none;
  color: #0a0a0a;
}

.pagination {
  float: right;
  margin-top: 20px;
}
.admin-user .table-responsive,
.admin-withdraw .table-responsive {
  background-color: white;
}
.admin-tab .nav-tabs {
  border-bottom: none !important;
}
.admin-content .register-form .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: #fbfbfb;
  border-radius: 4px;
  font-size: 14px;
  color: #495057;
}

select#formBasicFirst {
  background-color: white !important;
  color: #000 !important;
}
.table-responsive {
  margin-top: 20px;
}
input#formBasicPassword {
  background-color: white;
  color: black;
}
.toptitle {
  font-size: 19px;
}
/* a#uncontrolled-tab-example-tab-add {
  color: #009bff;
} */
/* .action-dropdown.action-buttons .dropdown button {
  background-color: #000;
} */
.admin-content button {
  /* background-color: #000 !important; */
  border-style: none;
  padding: 10px 30px 10px 24px;
}

.admin-content .sub-admin button {
  color: white;
  border-radius: 5px;
  background: #000;
}
.admin-content button:hover {
  /* background-color: #000 !important; */
  border-style: none;
  padding: 10px 30px 10px 24px;
}
.text-right button {
  background-color: #000;
  border-color: #000;
}
/* a#uncontrolled-tab-example-tab-list {
  color: #009cfe;
} */
.modal-content button {
  background-color: #000;
  float: right;
  border-color: #000;
}
/* .admin-content .ql-toolbar.ql-snow {
  background-color: #383d4a;
} */

.admin-login {
  background: #f5f5f5;
}

.admin-login-box {
  background: #ffffff;
}

.admin-login-box .form-group .form-control {
  background: #f5f5f5;
  color: rgb(3, 3, 3);
}

.admin-login-box .form-group .form-control:focus {
  box-shadow: none;
  background: #f5f5f5;
}
.admin-login-box input:-internal-autofill-selected {
  background: #000000 !important;
}

.admin-login-box h2 {
  color: rgb(8, 8, 8);
}

.admin-login-box label {
  color: rgb(0, 0, 0);
}
.admin-login a {
  color: #007bff;
}
.admin-login button {
  background-color: #000;
  border-color: #000;
}
.admin-login .login-button {
  float: right;
  margin-top: -35px;
}
.admin-login .login-button .l-btn:hover {
  background: black;
  border-color: #000;
}
.admin-login .login-button .l-btn:focus {
  background: black;
  border-color: #000;
}
.admin-content-view .input-box input {
  color: rgb(7, 7, 7) !important;
}
.admin-content-view input::placeholder {
  color: rgb(3, 3, 3) !important;
}
.admin-login .form-group input {
  color: rgb(7, 7, 7) !important;
}
.admin-login input#formBasicPassword {
  background-color: #f5f5f5;
  color: black;
}
.admin-content-view .form-group input {
  color: black !important;
}
.admin-content-view .ql-container p {
  color: #000 !important;
}
.admin-content-view .form-group select {
  color: black !important;
}
.modal-content .modal-body input {
  color: #000 !important;
}
.admin-matchmaking span {
  color: #383d4a !important;
}
.action-dropdown .btn-primary {
  background: #000 !important;
}
.action-buttons .btn {
  background: #000 !important;
}
.admin-content .btn-primary {
  color: #fff;
  background-color: #010101;
  border-color: #010101;
}
.add-subadmin-form .form-group label {
  color: #030303;
}
.ql-editor.ql-blank::before {
  color: rgb(0, 0, 0);
}

.admin-content .sub-admin button:disabled {
  background: #a2a2a2!important;
}
.new-del-popup {
  padding-bottom: 0;
}
.new-del-popup h4 {
  max-width: fit-content;
  font-size: 22px;
}
.new-del-popup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.tab-pane .admin-title  {
  font-size: 18px;
}
.tab-pane .form-control option,
 .form-control option:disabled,
 .delete-req .form-control option {
  color: #495057;
}
.delete-req .admin-title {
  color: rgb(0, 0, 0);
    padding: 4px 0 10px;
    font-size: 18px;
}
.delete-req .btn.btn-primary:hover,
.delete-req .btn.btn-primary:focus,
.delete-req .btn.btn-primary:active {
  background: #e10808;
  border-color: #e10808;
}

/* Date Picker */

.delreq-date .react-datepicker-wrapper {
  display: block;
}
.delreq-date .react-datepicker-wrapper input {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem 0.75rem;
  width: 100%;
}
.delreq-date .react-datepicker-wrapper input:focus,
.delreq-date .react-datepicker-wrapper input:active {
  background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}
.delreq-date .react-datepicker-popper button.react-datepicker__navigation {
  background: none;
    padding: 0;
    border-style: solid;
    border-color: transparent;
}
.delreq-date .react-datepicker-popper button.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
}
.delreq-date .react-datepicker-popper button.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
}

/* Promotion User */
.promotion-table .table th,
.promotion-table .table td {
  min-width: 150px;
}
.promotion-table .table th {
  text-transform: uppercase;
}
label.edit-heading {
  font-size: 20px;
  font-weight: 600;
}
.admin-title.promotionuser-heading {
  padding: 20px 0 0;
}
.admin-title.promotionuser-tableheading {
  padding: 0 0 10px;
    font-size: 18px;
}

/* Disclaimer POpup */
.disclaimer-content {
  padding: 10px;
}
.send-msg-editor .ck.ck-label {
    display: none !important;
}
.send-msg-editor .ck-button {
  padding:5px !important;
  width: 50px !important;
  cursor: pointer !important;
}
.send-msg-editor .ck-button:hover {
  color: #0066CC !important;
}
.ck-restricted-editing_mode_standard p {
  font-family: 'Calibri' !important;
  font-size: 14px!important;
}