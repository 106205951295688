.forget-section {
  background: #383a44;
  padding: 100px 0px;
}

.forget-form {
  background: #ffffff;
  max-width: 500px;
  margin: 0px auto;
  padding: 50px;
  border-radius: 5px;
}

.forget-form h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: rgb(3, 3, 3);
}

.forget-form .form-group .form-control {
  height: auto;
  padding: 10px 15px;
  background: #f5f5f5;
  border: 1px solid #3e4548;
  border-radius: 4px;
  font-size: 14px;
  color: #ddd;
}

.forget-form .form-group .form-control:focus {
  box-shadow: none;
  background: #f5f5f5;
}

.login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.forget-form p {
  text-align: center;
  color: #fff;
}

.forget-box {
  max-width: 400px;
  margin: 40px auto;
}

.forget-form .reset-btn {
  background: #025b95;
  border: none;
  transition: all 0.5s;
  padding: 10px 40px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border-radius: 4px;
  margin: 0px auto;
  display: block;
}

.reset-box {
  max-width: 400px;
  margin: 50px auto;
  margin-bottom: 0;
}

.forget-form .form-group {
  margin-bottom: 25px;
}

.forget-form .form-label {
  font-size: 14px;
  color: #afb4d0;
  font-weight: 400;
}
