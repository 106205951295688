.page-404 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #f7f7f7;
}

.error-content {
    background: #fff;
    padding: 30px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    text-align: center;
    box-shadow: 0px 0px 20px #00000010;
}

.error-content a {
    max-width: 200px;
    display: block;
    margin: 0px auto;
    background: #025b95;
    text-align: center;
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin-top: 30px;
    transition: all 0.5s;
}

.error-content a:hover,
.error-content a:focus {
    text-decoration: none;
    background: #034a79;
    color: #fff;
}

.error-content img {
    max-width: 602px;
    width: 100%;
}