.my-profile {
  position: relative;
  padding: 20px 0px;
  background: #383a44;
  min-height: 700px;
}

.my-profile-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.my-profile-content {
  width: calc(100% - 300px);
  padding-left: 40px;
}

.my-profile-sidebar {
  width: 300px;
  background: #383d4a;
  padding: 30px;
  border-radius: 4px;
}

.my-profile-content h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  padding-bottom: 20px;
  padding-top: 5px;
  color: #fff;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-pic img {
  width: 70px;
  height: 70px;
  border-radius: 5px;
  object-fit: cover;
}

.user-detail h4 {
  margin: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 2px;
}

.user-detail {
  padding-left: 20px;
}

.user-detail span {
  color: #808080;
  font-size: 13px;
}

.my-profile-nav ul {
  margin: 0;
}

.my-profile-nav ul .nav a {
  display: block;
  width: 100%;
  padding: 15px 0px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

.my-profile-nav ul .nav a.active {
  color: #2697e0;
}

.my-profile-nav ul .nav {
  flex-wrap: wrap;
  padding-top: 30px;
}

.my-profile-nav ul .nav a i {
  margin-right: 10px;
}

.my-profile-nav ul .nav a:hover {
  text-decoration: none;
}

.user-detail a:hover {
  text-decoration: none;
}

/********** Account Setting ************/

.edit-profile-content .register-form {
  max-width: 100%;
  background: #383d4a;
  padding: 35px;
  border-radius: 5px;
}

.edit-profile-content .register-form .form-label {
  font-size: 14px;
  color: #fff;
  font-weight: 400;
}

.edit-profile-content {
  padding: 0px 0px;
}

.edit-profile-content .s-btn.btn.btn-primary {
  margin-top: 20px;
}

[type="file"] {
  height: 0;
  overflow: hidden;
  width: 0;
}

[type="file"] + label {
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-size: inherit;
  font-weight: 500;
  margin-bottom: 1rem;
  outline: none;
  padding: 10px 50px;
  position: relative;
  transition: all 0.3s;
  vertical-align: middle;
}

[type="file"] + label:hover {
  background-color: #d3460d;
}

[type="file"] + label.btn-2 {
  background-color: #383a44;
  border-radius: 50px;
  overflow: hidden;
  margin: 0;
}

[type="file"] + label.btn-2::before {
  color: #fff;
  content: "\f093";
  font-family: "FontAwesome";
  font-size: 100%;
  height: 100%;
  right: 130%;
  line-height: 2.9;
  position: absolute;
  top: 0px;
  transition: all 0.3s;
}

[type="file"] + label.btn-2:hover {
  background-color: #009bff;
  color: #fff;
}

[type="file"] + label.btn-2:hover::before {
  right: 70%;
}

.uplaod-profile-photo {
  text-align: center;
}

.uplaod-profile-photo .user-pic img {
  width: 100px;
  height: 100px;
  margin-bottom: 10px;
  border-radius: 50% !important;
}

.uplaod-profile-photo {
  padding-bottom: 20px;
}
.my-profile {
  background-color: #f5f5f5;
}
.my-profile-sidebar {
  background-color: white;
}
.edit-profile-content .register-form {
  background: #ffffff;
}
.edit-profile-content .register-form .form-label {
  color: #060606;
  background-color: #f5f5f5;
}
.my-profile-nav ul .nav a {
  color: #0e0e0e;
}
.user-detail h4 {
  color: #060606;
}
.admin-content .register-form .form-group .form-control:focus {
  background: #fbfbfb;
  color: #030303;
}
.my-profile-content {
  padding-left: 0px;
}
