.policy-quill .ql-snow .ql-stroke {
    fill: none;
    stroke: #000;
}
.policy-quill .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #000;
}
.policy-quill .ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid #000;
    color: #000;
}
.policy-quill .ql-snow .ql-fill, .policy-quill .ql-snow .ql-stroke.ql-fill {
    fill: #000;
}
.policy-quill .quill {
    height: 100%;
    color: #000;
}
.policy-quill .ql-editor.ql-blank::before {
    color: #000;
}
.ql-editor {
    font-family: Calibri, sans-serif;
  }
.ql-container.ql-snow {
    height: 300px;
}
.view-popup h3 {
    font-size: 16px;
    margin-bottom: 15px;
}
.ql-font-arial                {  font-family: 'Arial'             , sans-serif;}
.ql-font-roboto               {  font-family: 'Roboto'            , sans-serif;}
.ql-font-calibri              {  font-family: 'Calibri'           , sans-serif;}
.ql-font-courier              {  font-family: 'Courier New'       , serif;    }
.ql-font-georgia              {  font-family: 'Georgia'           , sans-serif;}
.ql-font-lucida               {  font-family: 'Lucida Sans Unicode', sans-serif;}
.ql-font-open                 {  font-family: 'Open Sans'         , sans-serif;}
.ql-font-tahoma               {  font-family: 'Tahoma'            , sans-serif;}
.ql-font-times                {  font-family: 'Times New Roman'   , sans-serif;}
.ql-font-trebuchet            {  font-family: 'Trebuchet Ms'      , sans-serif;}
.ql-font-verdana              {  font-family: 'Verdana'           , sans-serif;}
.ql-font-raleway             {  font-family: 'Raleway'           , sans-serif;}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=arial]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=arial]::before {
    content: 'Arial';  font-family: 'Arial', 'Helvetica';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=calibri]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=calibri]::before {
    content: 'Calibri'; font-family: 'Calibri', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=roboto]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=roboto]::before {
    content: 'Roboto'; font-family: 'Roboto', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier"]::before {
    content: 'Courier New';  font-family: 'Courier New';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=georgia]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=georgia]::before {
    content: 'Georgia';  font-family: 'Georgia', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
    content: 'Lucida Sans';  font-family: 'Lucida Sans Unicode', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="open"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="open"]::before {
    content: 'Open Sans';  font-family: 'Open Sans', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=tahoma]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=tahoma]::before {
    content: 'Tahoma';  font-family: 'Tahoma';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="times"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="times"]::before {
    content: 'Times New Roman';  font-family: 'Times New Roman';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="trebuchet"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet"]::before {
    content: 'Trebuchet MS';  font-family: 'Trebuchet MS';
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=verdana]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=verdana]::before {
    content: 'Verdana';  font-family: 'Verdana', sans-serif;
}
.ql-picker.ql-font .ql-picker-label[data-value="raleway"]::before, 
.ql-picker.ql-font .ql-picker-item[data-value="raleway"]::before
{
    font-family: "Raleway", cursive;;
    content: "Raleway" !important;
}