.advertise-view .mob-view{
display: none;
}
.advertise-view img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}
@media screen and (max-width: 767px) {
    .advertise-view .web-view{
        display: none;
        }
        .advertise-view .mob-view{
            display: block;
            }
}
